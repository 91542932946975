import * as React from "react";

import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

export default function SocialMedia({ className }) {
  return (
    <div className={className}>
      <a
        className="flex items-center w-auto"
        href="https://www.instagram.com/lewis__seymour/"
      >
        <FaInstagram className="m-5 ml-0" size={30} />
        <p className="text-xl">@lewis__seymour</p>
      </a>
      <a
        href=" https://www.facebook.com/profile.php?id=100009335274256"
        className="flex items-center w-auto"
      >
        <FaFacebook className="m-5 ml-0" size={40} />
        <p className="text-xl">Lewis Seymour</p>
      </a>

      <a
        href="https://www.youtube.com/channel/UCxxIQTty-KKYvHnaW4IzCkQ"
        className="flex items-center w-auto"
      >
        <FaYoutube className="m-5 ml-0" size={40} />
        <p className="text-xl">Lewis Seymour</p>
      </a>
    </div>
  );
}
