import * as React from "react";

import { Link } from "gatsby";

import SocialMedia from "./social-media";

import mangooLogo from "../images/mangoo-logo.png";

import MailingList from "./mailinglist";

export default function Footer() {
  const links = [
    { title: "Home", to: "/" },
    { title: "About", to: "/about/" },
    {
      title: "Portfolio",
      to: "/portfolio/",
      children: [
        { title: "photography", to: "#photos" },
        { title: "videos", to: "#video" },
      ],
    },
    {
      title: "Learn And Grow",
      to: "/social-impact/",
      children: [
        { title: "Blog", to: "/blog/" },
        { title: "Monday motivation", to: "/monday-motivation/" },
        { title: "Featured", to: "/featured/" },
      ],
    },
    { title: "About", to: "/about/" },
  ];
  return (
    <div className="bg-black relative">
      <div className="text-white border-t flex flex-col sm:flex-row border-white sm:divide-x divide-y sm:divide-y-0 divide-white p-10 overflow-hidden">
        <div className="sm:w-1/2 sm:pr-20">
          <h2 className=" font-BritanicaBold text-3xl mb-10">Visual Insight</h2>
          <div className="flex flex-col sm:flex-row w-full justify-between">
            {links.map((link, index) => (
              <div key={index}>
                <Link to={link.to}>
                  <h3 className="font-Arial text-xl pb-3 mt-5">{link.title}</h3>
                </Link>
                <div className="flex flex-col">
                  {link.children &&
                    link.children.map((child, index2) => (
                      <Link key={index2} to={child.to}>
                        {child.title}
                      </Link>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:pl-20 sm:w-1/2">
          <SocialMedia />
          <div className="flex flex-col sm:flex-row items-start sm:items-center text-xl gap-5">
            Join my mailing list: <MailingList />
          </div>
        </div>
      </div>
      <a
        href="https://www.mangoo.co.za/"
        className="flex justify-center flex-col sm:flex-row m-auto items-center text-xl py-5"
      >
        Built by:
        <img src={mangooLogo} alt="mangoo-logo" className="h-24 ml-10" />
      </a>
    </div>
  );
}
