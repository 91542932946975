import React, { useState, useEffect } from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import logo from "../images/logo.png";

import { FaBars } from "react-icons/fa";
import { BsX } from "react-icons/bs";

import SocialMedia from "./social-media";

export default function Header({ onNav, landing }) {
  const data = useStaticQuery(graphql`
    {
      captain: file(relativePath: { eq: "captain.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 900, maxWidth: 2560, cropFocus: SOUTH) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const captain = data["captain"].childImageSharp.fluid;
  const [width, setWidth] = useState(0);
  const [yOffset, setYOffset] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.onscroll = () => {
      if (window.scrollY > 300) {
        setYOffset(true);
      }
      if (window.scrollY < 300) {
        setYOffset(false);
      }
    };
  }, []);

  if (width < 1024) {
    return <Mobile onNav={onNav} />;
  }

  return (
    <Desktop
      onNav={onNav}
      captain={captain}
      landing={landing}
      yOffset={yOffset}
    />
  );
}

function Mobile({ onNav }) {
  const [drawer, toggleDrawer] = useState(false);
  return (
    <div className="text-black sticky top-0 bg-white z-40">
      <div className="flex w-full p-5 items-center ">
        {/* <img src={logo} alt={logo} className="w-1/5 " /> */}
        <h1 className="text-4xl font-BritanicaBold">Visual insight</h1>
        <FaBars
          className="text-5xl ml-auto right-0 z-10"
          onClick={() => toggleDrawer(true)}
        />
      </div>

      <div
        className={
          "  bg-white fixed inset-0 h-screen z-20 transition-all duration-200 ease-in-out overflow-hidden pt-10 flex flex-col justify-evenly " +
          (drawer ? "w-full" : "w-0")
        }
      >
        <Link to="/">
          <img src={logo} alt={logo} className="m-auto w-1/3" />
        </Link>

        <BsX
          className="absolute right-0 top-0 text-4xl text-black m-5"
          onClick={() => toggleDrawer(false)}
        />

        <ul className="flex flex-col items-center justify-around flex-grow">
          <MobileNavItem title="About" link="/about/" onNav={onNav} />
          <MobileNavItem title="Portfolio" link="/portfolio/" onNav={onNav} />
          <MobileNavItem
            title="Learn And Grow"
            link="/social-impact/"
            onNav={onNav}
          />
          <MobileNavItem title="Contact" link="/contact/" onNav={onNav} />
        </ul>
        <SocialMedia className="mx-auto w-min " />
      </div>
    </div>
  );
}

function Desktop({ onNav, captain, landing, yOffset }) {
  return (
    <>
      <div className="text-white  m-auto pt-10 pb-10 z-40 relative">
        <div className="absolute inset-0 -left-20 -z-1">
          {!landing && (
            <Img
              fluid={captain}
              alt="landing"
              className="h-full"
              imgStyle={{
                zIndex: -1,
                maxHeight: "400px",
                objectPosition: "52% 0",
                filter: "brightness(0.4)",
              }}
            />
          )}
        </div>

        <div className="flex m-auto justify-center items-end max-w-7xl">
          <ul className="flex items-center justify-end w-2/5">
            <NavItem title="About" link="/about/" onNav={onNav} />
            <NavItem title="Portfolio" link="/portfolio/" onNav={onNav} />
          </ul>

          <div className="w-1/5 h-auto">
            <Link to="/">
              <img src={logo} alt={logo} />
            </Link>
          </div>

          <ul className="flex justify-start items-center w-2/5">
            <NavItem
              title="Learn And Grow"
              link="/social-impact/"
              onNav={onNav}
            />
            <NavItem title="Contact" link="/contact/" onNav={onNav} />
          </ul>
        </div>
        <Link to="/">
          <h1 className="text-center mt-5 font-BritanicaBold">
            Visual Insight
          </h1>
        </Link>
        {!landing && (
          <SocialMedia className="flex max-w-7xl justify-around m-auto mt-10" />
        )}
      </div>
      <div
        className={
          "fixed transition duration-200 top-0 w-full bg-white z-40 p-5 text-black  items-center  opacity-0 flex " +
          (yOffset ? "opacity-100" : "opacity-0")
        }
      >
        <Link to="/">
          <h1 className=" font-BritanicaBold">Visual Insight</h1>
        </Link>
        <ul className="flex ml-auto mr-0 items-center">
          <NavItem
            title="About"
            link="/about/"
            onNav={onNav}
            border="border-black"
          />
          <NavItem
            title="Portfolio"
            link="/portfolio/"
            onNav={onNav}
            border="border-black"
          />
          <NavItem
            title="Learn And Grow"
            link="/social-impact/"
            onNav={onNav}
            border="border-black"
          />
          <NavItem
            title="Contact"
            link="/contact/"
            onNav={onNav}
            border="border-black"
          />
        </ul>
      </div>
    </>
  );
}

function NavItem({ title, link, onNav, border = "border-white" }) {
  return (
    <li className="text-3xl px-5 hover:text-gray-300 uppercase">
      <Link
        to={link}
        activeClassName={"border-b pointer-events-none" + border}
        onClick={onNav}
      >
        {title}
      </Link>
    </li>
  );
}

function MobileNavItem({ title, link, onNav }) {
  return (
    <li className="text-4xl text-black uppercase">
      <Link
        to={link}
        activeClassName="border-b border-black pointer-events-none"
        onClick={onNav}
      >
        {title}
      </Link>
    </li>
  );
}
