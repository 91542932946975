import React, { useState, useEffect } from "react";

export default function MailingList() {
  const [email, setEmail] = useState("");
  const [origin, setOrigin] = useState("");
  useEffect(() => {
    setOrigin(window.location.origin);
  }, []);
  const submit = () => {
    var url = new URL(origin + "/.netlify/functions/add-to-mailinglist?");

    const params = {
      email: email,
    };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    setEmail("");
    fetch(url);
  };

  return (
    <div className="text-black flex items-center border border-white rounded-lg">
      <input
        className="bg-white  text-xl focus:ring-0 outline-none p-3 border-white border-r rounded-md bg-opacity-75 w-5/6"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={submit} className="p-2 bg-opacity-75 px-3 text-white">
        Join
      </button>
    </div>
  );
}
