import * as React from "react";

import Header from "./header";

import Footer from "./footer";

import { FaSpinner } from "react-icons/fa";

function Layout({ landing = false, children }) {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div
        key={`loader`}
        id="___loader"
        // className="inset-0 h-screen w-full flex fixed items-center bg-white z-100"
        style={{
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 100,
        }}
      >
        <FaSpinner
          className="animate-spin m-auto"
          style={{ margin: "auto", animation: "spin linear 1s infinite" }}
          size={80}
        />
      </div>
    );
  }
  return (
    <div>
      <title>Visual insight</title>
      <Header onNav={() => setLoading(true)} landing={landing} />
      {children}
      <Footer />
    </div>
  );
}

export default Layout;
